import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
Vue.use(VueRouter);
export const routes = [
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/Print'),
    meta: {
      rules: 0,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      rules: 0,
    },
  },
  {
    path: '/',
    name: '',
    redirect: 'index',
    component: Home,
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/index.png',
      name: '首页',
      rules: 1,
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/index/index'),
        name: 'index',
        meta: {
          name: '首页',
          rules: 1,
          form: 1,
        },
      },
    ],
  },
  {
    path: '/service',
    name: 'service',
    component: Home,
    redirect: '/service/orderList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/goods.png',
      name: '服务',
      title: '服务管理',
      rules: 1,
    },
    children: [
      {
        path: '/service/orderList',
        component: () => import('@/views/service/OrderList'),
        name: 'service-orderList',
        meta: {
          name: '服务订单',
          rules: 1,
        },
      },
      {
        path: '/service/salesRefund',
        component: () => import('@/views/service/SalesRefund'),
        name: 'service-salesRefund',
        meta: {
          name: '服务售后',
          rules: 1,
        },
      },
      {
        path: '/service/salesHandle',
        component: () => import('@/views/service/SalesHandle'),
        name: 'service-salesHandle',
        meta: {
          name: '售后处理',
          relation: 'service-salesRefund',
          rules: 0,
        },
      },
      {
        path: '/service/orderInfo',
        component: () => import('@/views/service/OrderInfo'),
        name: 'service-orderInfo',
        meta: {
          name: '订单信息',
          relation: 'service-orderList',
          rules: 0,
        },
      },
      {
        path: '/service/evaluate',
        component: () => import('@/views/service/evaluate'),
        name: 'service-evaluate',
        meta: {
          name: '服务评价',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/user',
    name: 'user',
    component: Home,
    redirect: '/user/settedList',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/set1.png',
      name: '用户',
      title: '用户管理',
      rules: 1,
    },
    children: [
      {
        path: '/user/settedList',
        component: () => import('@/views/user/sourceCenter/settedList'),
        name: 'user-sourceCenter',
        meta: {
          name: '技工列表',
          rules: 1,
        },
      },
      {
        path: '/user/settedListInfo',
        component: () => import('@/views/user/sourceCenter/settedListInfo'),
        name: 'user-sourceCenter',
        meta: {
          name: '技工详情',
          form: 1,
          rules: 0,
        },
      },
      {
        path: '/user/addSettled',
        component: () => import('@/views/user/sourceCenter/addSettled'),
        name: 'user-sourceCenter',
        meta: {
          name: '添加技工',
          form: 1,
          rules: 0,
        },
      },
      //  {
      //    path: '/set/address',
      //    name: 'set-address',
      //    component: () => import('@/views/set/Address'),
      //    meta: {
      //      name: '退货地址配置',
      //      rules: 1,
      //    },
      //  },
      // {
      //   path: '/set/freightlist',
      //   name: 'set-freightlist',
      //   component: () => import('@/views/set/Freightlist'),
      //   meta: {
      //     name: '运费配置',
      //     rules: 1,
      //   },
      // },
      // {
      //   path: '/set/freight',
      //   name: 'set-freight',
      //   component: () => import('@/views/set/Freight'),
      //   meta: {
      //     name: '添加运费',
      //     rules: 0,
      //     form: 1,
      //     relation: 'set-freightlist',
      //   },
      // },
      // {
      //   path: '/set/freightEdit',
      //   name: 'set-freightEdit',
      //   component: () => import('@/views/set/Freight'),
      //   meta: {
      //     name: '编辑运费',
      //     rules: 0,
      //     form: 1,
      //     relation: 'set-freightlist',
      //   },
      // },
    ],
  },
  {
    path: '/finance',
    name: 'finance',
    component: Home,
    redirect: '/finance/accounts',
    meta: {
      icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/homeMenu/money.png',
      name: '财务',
      title: '财务管理',
      rules: 1,
    },
    children: [
      {
        path: '/finance/accounts',
        component: () => import('@/views/finance/accounts'),
        name: 'finance-accounts',
        meta: {
          name: '财务对账',
          rules: 1,
        },
      },
      {
        path: '/finance/withdraw',
        name: 'finance-withdraw',
        component: () => import('@/views/finance/withdraw'),
        meta: {
          name: '物业对账',
          rules: 1,
        },
      },
      {
        path: '/finance/withdrawDetail',
        name: 'finance-withdrawDetail',
        component: () => import('@/views/finance/withdrawDetail'),
        meta: {
          name: '提现记录',
          relation: 'finance-withdraw',
          rules: 0,
        },
      },
      {
        path: '/finance/income',
        name: 'finance-income',
        component: () => import('@/views/finance/income'),
        meta: {
          name: '服务收益',
          rules: 1,
        },
      },
    ],
  },
  
];
const createRouter = () =>
  new VueRouter({
    base: process.env.BASE_URL,
    routes,
  });
const router = createRouter();
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next();
  } else {
    let token = localStorage.getItem('token');
    if (!token) {
      router.push('/login').catch(err => err);
    } else {
      next();
    }
  }
});
export default router;
