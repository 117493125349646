const api = {
  user: {
    login: '/Property/Login/login',
    logout: '/Login/logout',
    userInfo: '/Property/Login/self',
    addFiles: '/Property/Upload/addFiles',
    imgList: '/Property/image/list',
    shopInfo: '/Property/info', //店铺信息
    artisanList: '/Property/Artisan/list',
    addArtisan: '/Property/Artisan/add',
    artisanInfo: '/Property/Artisan/info',
    communityList: '/Property/Artisan/communityList',
    userList: '/Property/Artisan/userList',
    classifyList: '/Property/Artisan/classifyList',
  },
  member: {
    addGroup: '/Property/UserOpenCluster/add',
    groupList: '/Property/UserOpenCluster/list',
    groupInfo: '/Property/UserOpenCluster/info',
    groupEdit: '/Property/UserOpenCluster/edit',
    groupDel: '/Property/UserOpenCluster/delete',
    getGroupNum: '/Property/UserOpenCluster/getClusterNum',
    getRecommend: '/Property/UserOpenCluster/getRecommend',
    editUser: '/Property/UserOpen/edit',
  },
  //权限
  //  auths: {
  //    add: '/auths/add', //添加菜单权限
  //    getAll: '/auths/getAll', //获取菜单列表
  //    update: '/auths/update', //编辑菜单权限
  //    getOne: '/auths/getOne', //获取菜单详情
  //    get_menu: '/auths/get_menu', //获取对应账号的菜单
  //    get_tree_list: '/auths/get_tree_list', //获取总菜单树
  //    addRoles: '/Roles/add', //添加角色
  //    editRoles: '/Roles/update',
  //  },
  goods: {
    goodsClass: '/Property/GoodsClassify/list',
    goodsAdminClass: '/Property/GoodsClassify/adminList',
    addGoods: '/Property/Goods/add',
    addType: '/Property/GoodsClassify/add',
    list: '/Property/Goods/list',
    editInfo: '/Property/Goods/editInfo', //编辑商品信息
    delType: '/Property/GoodsClassify/del',
    delGoods: '/Property/Goods/del',
    editGoods: '/Property/Goods/edit',
    editType: '/Store/GoodsClassify/edit',
    goodsInfo: '/Store/Goods/info',
    shiftGoods: '/Store/Goods/shiftGoods',
    commentList: '/Store/GoodsComment/list', //评价列表
    reply: '/Store/GoodsComment/reply', //回复
    setStatus: '/Store/GoodsComment/setStatus', //评价显示隐藏
    delComment: '/Store/GoodsComment/del', //删除评价
    setPutaway: '/Store/Goods/setPutaway', //设置上架\下架

    isSelect: '/Store/Goods/isSelect', //是否有默认运费模块
    export: '/Store/Goods/export', //导出商品
    import: '/Store/Goods/import', //导入商品
    copyGoods: '/Store/Goods/copyGoods', //复制商品
    exportGoodsClass: '/Store/GoodsClassify/export', //导出商品分类
    wxCode: '/Store/Goods/wxCode',
    setFreight: '/Store/Goods/setFreight',
    setClassify: '/Store/Goods/setClassify',
    groupGoodsList: '/Store/Group/groupGoodsList',
  },
  service: {
      ClassifyList: '/Community/ServiceClassify/list',
      ClassifyEditInfo: '/Community/ServiceClassify/editInfo',
      delClassify: '/Community/ServiceClassify/del',
      ClassifyEdit: '/Community/ServiceClassify/edit',
      ClassifyAdd: '/Community/ServiceClassify/add',
      list: '/Community/Service/list',
      add: '/Community/Service/add',
      info: '/Community/Service/info',
      edit: '/Community/Service/edit',
      del: '/Community/Service/del',
      editInfo: '/Community/Service/editInfo',
      orderList: '/Property/ServiceOrder/getList',
      getNum: '/Property/ServiceOrder/getNum',
      orderInfo: '/Property/ServiceOrder/detail',
      cancleOrder: '/Property/ServiceOrder/cancle',
      orderRefundList: '/Property/ServiceOrderRefund/getList',
      orderRefundNum: '/Property/ServiceOrderRefund/getNum',
      orderRefundDeal: '/Property/ServiceOrderRefund/deal',
      orderRefundInfo: '/Property/ServiceOrderRefund/detail',
      orderComment: '/Property/ServiceOrderComment/getList',
      orderCommentReply: '/Property/ServiceOrderComment/reply',
      orderFinanceIncome: '/Property/ServiceOrder/financeIncome',
      communityList: '/Property/ServiceOrder/getCommunityList',
      artisanList: '/Property/ServiceOrder/getAtisanList',
      orderDispatch: '/Property/ServiceOrder/dispatchOrder',
      signBill: '/Property/ServiceOrder/signBill',
  },
  order: {
    setExpires: '/Store/Shop/setOrderExpires', //设置订单过期时间
    list: '/Store/order/list', //获取订单列表
    info: '/Store/order/info', //获取订单详情
    setSend: '/Store/order/setSend', //订单发货接口
    num: '/Store/order/stateNum', //订单数量
    express: '/Store/express/list', //物流信息
    logisticsQuery: '/Store/Order/logisticsQuery', //物流信息查询
    setSystemMessage: '/Store/Order/setSystemMessage', //添加系统备注信息
    changePrice: '/Store/Order/changePrice', //修改订单价格
    cancel: '/Store/Order/cancel', //取消未付款订单
    cancelList: '/Store/Orderrefundreason/list', //退款原因
    printOrder: '/Store/Order/printOrder', //打印订单
    handleRefund: '/Store/Orderrefund/handle', //处理退款
    salesList: '/Store/Orderrefund/list', //售后列表
    refundInfo: '/Store/Orderrefund/info', //退款详情
    receive: '/Store/Orderrefund/receive', //商家收货
    refund: '/Store/Orderrefund/refund', //原路退回到钱包
    confirmReceipt: '/Store/order/confirmReceipt', //订单确认收货
    refundNum: '/Store/Orderrefund/stateNum', //退款订单数量
    editTracking: '/Store/Order/editTracking', //编辑物流
  },
  store: {
    storeList: '/Store/list', //门店列表
    addStore: '/Store/add', //添加门店
    editStore: '/Store/edit', //编辑门店
    delStore: '/Store/del', //删除门店
    editAccount: '/Store/editAccount', //对账管理
    goodsList: '/Store/goodsList', //商品管理列表
    storeUsersList: '/StoreUsers/list', //店员列表
    addStoreUsers: '/StoreUsers/add', //添加店员
    editStoreUsers: '/StoreUsers/edit', //编辑店员
    delStoreUsers: '/StoreUsers/del', //删除店员
    goodsAuditList: '/Store/storeGoodsAuditList', //商品审核列表
    goodsAudit: '/Store/storeGoodsAudit', //商品审核
    goodsAuditLog: '/Store/storeGoodsAuditLog', //商品审核详情
    withdrawLog: '/Store/withdrawLog',
    getStatus: '/Store/getStatus',
    withdrawLogInfo: '/Store/withdrawLogInfo',
    withdrawnList: '/Store/withdrawnList',
    upStatus: '/Store/upStatus',
    correlationGoods: '/Store/correlationGoods',
    StoreUsersedit: '/StoreUsers/edit',
    StoreUsersinfo: '/StoreUsers/info',
    checkInStatus: '/StoreSettled/settledStatus',
    StoreSettled: '/StoreSettled/list',
    SettledupStatus: '/StoreSettled/upStatus',
    StoreExport: '/Store/export',
    withdrawLogExport: '/Store/withdrawLogExport',
    storeErCode: '/Store/wxCode',
  },
  set: {
    editVideo: '/Store/Upload/editVideo', // 添加视频信息
    freightAdd: '/Store/Freight/add', //添加运费
    freightList: '/Store/Freight/list', //运费列表
    freightDel: '/Store/Freight/del', //删除运费
    freightEdit: '/Store/Freight/edit', //编辑运费
    wxCode: '/Store/WxCode/getPreAuthorizationUrl', //获取微信授权
    wxShopSetting: '/Store/ShopSetting/add', //微信支付配置
    wxAuditStatus: '/Store/WxCode/getAuditStatus', //微信审核状态
    submitAudit: '/Store/WxCode/submitAudit', //提交审核
    wxundocodeaudit: '/Store/WxCode/undocodeaudit', //微信小程序审核撤回
    rejectList: '/Store/WxCode/getThirdLog', // 获取驳回原因列表
    wxtesterBind: '/Store/WxCode/testerBind', //wx绑定体验者
    wxtesterUnbind: '/Store/WxCode/testerUnbind', //wx解绑体验者
    testerList: '/Store/WxCode/testerList', // 体验者列表
    wxRelease: '/Store/WxCode/release', //wx小程序发布
    wxCommit: '/Store/WxCode/commit', //wx提交审核
    wxqrCode: '/Store/WxCode/qrCode', //wx获取体验码
    wxApp: '/Store/WxCode/getWxapp', // wx设置
    setWxapp: '/Store/WxCode/setWxapp', // 设置微信设置
    Wxticket: '/Store/api/Wxticket/handleAuthorize', //微信授权
    addArticleClass: '/Store/ArticleClassify/add', //添加文章分类
    listArticleClass: '/Store/ArticleClassify/list', //文章分类列表
    editArticleClass: '/Store/ArticleClassify/edit', //编辑文章分类
    delArticleClass: '/Store/ArticleClassify/del', //删除文章分类
    addArticle: '/Article/add', //添加文章
    editArticle: '/Article/edit', //编辑文章
    delArticle: '/Article/del', //删除文章
    listArticle: '/Article/list', //文章列表
    address: '/Store/Shipper/list', //地址
    addAddress: '/Store/shipper/add', //添加地址
    editAddress: '/Store/shipper/edit', //编辑地址
    delAddress: '/Store/shipper/del', //删除地址
    setDefaultAddress: '/Store/shipper/setDefault', //设置默认地址
    setDefaultFreight: '/Store/Freight/setDefault', //设置默认运费
  },
  data: {
    survey: '/Statistics/overview', //概况
    analysis: '/Statistics/transactionAnalysis', //分析
    goodsAnalyse: '/Goods/goodsAnalyse', //商品分析
    goodsData: '/Goods/goodsData', //商品数据
    goodsRankingList: '/Goods/goodsRankingList', //商品排行榜
    goodsRankingListExport: '/Goods/goodsRankingListExport', //导出商品排行榜
    dataOverView: '/NewStatistics/dataOverView',
    goodsAnalyse1: '/GoodsStatistics/goodsAnalyse',
    salesAnalyse: '/GoodsStatistics/salesAnalyse',
    abnormalGoods: '/GoodsStatistics/abnormalGoods',
    goodsDetail: '/GoodsStatistics/goodsDetail',
    ankingList: '/GoodsStatistics/ankingList',
    storeRankingList: '/GoodsStatistics/storeRankingList',
    refundGoods: '/GoodsStatistics/refundGoods',
    getThirtyData: '/NewStatistics/getThirtyData',
    successRefund: '/NewStatistics/successRefund',
    conversion: '/NewStatistics/conversion',
    realTimeOverview: '/NewStatistics/realTimeOverview',
    todayTrend: '/NewStatistics/todayTrend',
    store_transaction: '/NewStatistics/store_transaction',
    getRefundBlock: '/NewStatistics/getRefundBlock',
  },
  // 首页
  homeIndex: {
    indexData: '/Statistics/quantity',
    setBaseInfo: '/Shop/setBaseInfo',
    readMsg: '/shop/readMessage',
    tabsMessage: '/Message/search',
    typeList: '/Message/typeList',
    unReadCount: '/Message/unReadCount',
  },
  // 首页
  index: {
    getData: '/Property/StatisticalData/getData', //获取统计首页
    getDateData: '/Property/StatisticalData/getDateData', //首页获取 按月 按年数据
  },
  // 上传文件
  upload: {
    addCert: '/Store/Upload/addCert',
  },
  finance: {
    orderList: '/Store/StoreWithdraw/orderList', //待提现订单列表
    withdraw: '/Property/FinancialVerify/withdraw', //提现
    withdrawList: '/Property/PropertyWithdraw/getList', //提现记录
    withdrawInfo: '/Property/PropertyWithdraw/detail', //详情(提现记录)
    withdrawInfoList: '/Store/StoreWithdraw/infoList', //提现订单(详情)
    commodityIncome: '/Store/Finance/commodityIncome', //获取商品收益
    financialVerify: '/Property/FinancialVerify/getList',
    getBankCard: '/Property/FinancialVerify/getBankCard',//获取银行卡
    editBankCard: '/Property/FinancialVerify/editBankCard',//编辑银行卡列表
    getAmount: '/Property/FinancialVerify/getAmount',//获取可提现金额
    getCheckingDetail: '/Property/FinancialVerify/getDetail', //对账详情
    checking:'/Property/FinancialVerify/checking',
    getLogDetail: '/Property/PropertyWithdraw/getLogDetail', //对账详情
    propertyWithdrawDetailTop: '/Property/PropertyWithdraw/detail', //物业对账
    propertyWithdrawDetail: '/Property/PropertyWithdraw/getCashLogList', //物业对账
  },
  // 素材中心
  sourceCenter: {
    ImagesGroupList: '/Store/ImagesGroup/list', //Property
    ImagesGroupSort: '/Store/ImagesGroup/sort', //图片分组排序
    ImagesGroupAdd: '/Store/ImagesGroup/add', // 添加图片分组
    ImagesGroupEdit: '/Store/ImagesGroup/edit', // 修改图片分组
    ImagesGroupDel: '/Store/ImagesGroup/del', // 删除图片分组
    ImageMove: '/Store/Image/move', // 移动图片分组
    ImageDel: '/Store/Image/dels', // 删除图片
    UpdateName: '/Store/Image/rename', //修改图片名字
    JwtList: '/Store/JwtImages/JwtList', // 站点外获取图片分类
    rename: '/Store/ImagesGroup/rename', // 修改文件夹名字
    moveGroup: '/Store/ImagesGroup/move', // 移动文件夹
  },
};

export default api;
